import React, { useContext } from "react"

import { GolbalStateContext } from "../context"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

import src from "../images/iStock-613759142.jpg"
import PageIntro from "../components/pageIntro"
import MenuReset from "../components/MenuReset"

const OsteopathyPage = props => {
  // eslint-disable-next-line no-unused-vars
  const [_, setGlobalState] = useContext(GolbalStateContext)

  const openEnquery = event => {
    event.preventDefault()
    setGlobalState(gs => ({
      ...gs,
      enquireOpen: true,
    }))
  }

  return (
    <Layout solidHeader>
      <SEO title="Osteopathy" />
      <MenuReset />

      <PageIntro>
        <h2>What is Osteopathy?</h2>
      </PageIntro>
      <Content>
        <div className="row">
          <div className="col">
            <div className="content">
              <p>
                Osteopathy is a form of manual healthcare. It is based on a
                series of principles which outline the link between the body’s
                structure and the way it functions. Osteopaths use a holistic
                approach to pain management allowing treatment to expand beyond
                the site of pain to the rest of the body.
              </p>
              <p>
                In Australia, Osteopaths are highly trained, primary healthcare
                practitioners who complete a minimum of 5 years of university
                study. Their education includes training in anatomy, physiology,
                pathology, general medical diagnosis and osteopathic techniques.
              </p>
              <p>
                Osteopaths are also trained to perform medical examinations of
                the musculoskeletal, cardiovascular, respiratory and nervous
                system. Combining the skills they have acquired through study,
                an Osteopath is able to recognise potential medical conditions
                and have the knowledge to identify when a referral should be
                made to other medical practitioners.
              </p>

              <h3>Philosophy of Osteopathy</h3>
              <p>
                The philosophy of osteopathy is deceptively simple. It is
                unified by scientific knowledge and extended to patient care.
                Osteopathic philosophy emphasizes the following principles
              </p>
              <ol>
                <li>The body is a unit</li>
                <li>
                  It has its own self-protecting and regulating mechanisms
                </li>
                <li>Structure and function are reciprocally interrelated</li>
                <li>
                  Rational Treatment considers the preceding three principles.
                </li>
              </ol>
              <p>
                Osteopathic treatment is based on these principles to ensure
                that nothing is forgotten when approaching a patient.
              </p>

              <p>
                Want more info{" "}
                <u
                  role="button"
                  onClick={openEnquery}
                  onKeyPress={openEnquery}
                  tabIndex={0}
                >
                  click here
                </u>
                <br />
                <br /> Book an Appointment now{" "}
                <u
                  role="button"
                  onClick={openEnquery}
                  onKeyPress={openEnquery}
                  tabIndex={0}
                >
                  click here
                </u>
              </p>
            </div>
          </div>
          <div className="col">
            <img src={src} alt="Treatment" />
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default OsteopathyPage
